<template>
	<div class="container">
		<div class="card mt-5">
			<div class="card-body">
				<article class="editor px-tablet-40" data-aos="fadeIn .7s ease-in-out-circ .2s, loop">
					<p style="text-align: center;"><strong>ALPHABATEM</strong><br/><strong><span
							style=";">WEBSITE USAGE</span></strong><br/><em><span
							style="text-decoration: underline;"><strong>TERMS AND CONDITIONS </strong></span><br/><span
							style=";">Date: 12.2.22 </span></em></p>
					<p style="text-align: center;"></p>

					<p>The following Terms and Conditions (“T&Cs”) govern your (“you” or the “User”) use
						of the Alphabatem website (the “Website”), owned and operated by Alphabatem
						LLC, a St. Vincent and the Grenadines registered entity (the “Company”). User and
						Company may be referred as Party or Parties hereinbelow. This document
						is not a solicitation for investment and does not pertain in any way to
						an offering of securities in any jurisdiction, including in the United States of America
						(U.S.A.).</p>

					<p>These T&Cs describe the usage of the Company’s Website. Please read these T&Cs carefully –
						by using the Website in any way, you acknowledge that you have read these T&Cs, and
						you agree to be fully bound by them. IF YOU DO NOT AGREE TO THESE T&CS, IN PART
						OR AT ALL, DO NOT ACCESS OR USE IN ANY WAY OR FORM THE WEBSITE.
					</p>

					<p>By accessing or using the Website, and to the extent permitted by law, you are agreeing not to
						hold any of the Company and its respective past, present, and future employees,
						officers, directors, contractors, consultants, equity holders, suppliers, vendors, service
						providers, parent companies, subsidiaries, affiliates, agents, representatives, predecessors,
						successors and assigns (the “Company Team”) liable for any losses or any special, incidental,
						or consequential damages arising from, or in any way connected to the content, usage or
						access to and of the Website.
					</p>

					<p>THE COMPANY RESERVES THE RIGHT TO CHANGE, MODIFY, ADD OR REMOVE ANY
						PORTION OF THESE T&Cs AT ANY TIME FOR ANY REASON. ALTHOUGH WE AIM TO
						INFORM KNOWN USERS OF SUCH CHANGES, THIS MAY NOT ALWAYS BE POSSIBLE.
						THUS, IT REMAINS YOUR OBLIGATION TO REVIEW THESE T&Cs PERIODICALLY. ANY
						CHANGES SHALL BE EFFECTIVE IMMEDIATELY UPON POSTING ON OUR WEBSITE. THE
						INFORMATION SET FORTH ON THE WEBSITE MAY NOT BE EXHAUSTIVE AND DOES NOT
						IMPLY ANY ELEMENTS OF A CONTRACTUAL RELATIONSHIP OR ANY KIND
						OF INVESTMENT CONTRACT. NOTHING ON THE WEBSITE CONSTITUTES
						PROFESSIONAL ADVICE. THE COMPANY DOES NOT GUARANTEE OR ACCEPT
						RESPONSIBILITY FOR THE ACCURACY, RELIABILITY, CURRENT STATE, OR
						COMPLETENESS OF THE AVAILABLE INFORMATION.</p>

					<ol>
						<li><strong>RESTRICTIONS ON DISTRIBUTION AND DISSEMINATION OF INFORMATION
						</strong>

						<ol>
							<li><p>No regulatory authority has examined or approved the Website’s content and/or these T&Cs, no
								action has been or will be taken under the laws, regulatory requirements, or rules of any
								jurisdiction and the publication, distribution, or dissemination of all or any part of the Website’s
								content and/or these T&Cs to you does not imply that the applicable laws, regulatory
								requirements or rules have been complied with.</p>
								<p>The distribution or dissemination howsoever of all or any part of the Website’s content and these
									T&Cs may be prohibited or restricted by the laws, regulatory requirements, and rules of certain
									jurisdictions. In the case where any such restriction applies, you are responsible for informing
									yourself in respect of the same and for observing any such restrictions which are applicable to
									your possession and/or dissemination of all or any part of the Website’s content and these T&Cs
									at your own expense and without liability to the Company.</p>
							</li>
							<li> Persons to whom a copy of all or any part of the Website’s content and these T&Cs has been
								distributed or disseminated, provided access to, or who otherwise have all or any part of the
								Website and these T&Cs in their possession shall not circulate it to any other persons,
								reproduce or otherwise distribute any information contained herein for any purpose whatsoever
								nor permit nor cause the same to occur.</li>
						</ol>
						</li>
						<li><strong>COPYRIGHT OF COMMUNICATIONS</strong>
						<ol>
							<li>You agree that any materials, information, or communications transmitted between you and the
								Company in any form, or between you and any other Company’s user as the case may be, are
								non-confidential and will become the sole, exclusive property of the Company.</li>
							<li> The Company will own all intellectual property rights to such communications or materials and
								can use or disseminate them in a completely unrestricted fashion for any legal purpose,
								commercial or otherwise, without notifying or compensating you. You hereby waive any right to
								litigation or recovery for perceived damages caused by the use of this information as is
								permissible by law.</li>
						</ol>
						</li>

						<li>
						<strong>WEBSITE USAGE LICENCE</strong>
							<ol>
								<li>The Company grants you a limited, nonexclusive, non-transferable licence (“Licence”) to access
									its network (proprietary or hosted) and use its Website and services available thereunder. As a
									condition of your use of the Website, you warrant to the Company that you will not use the
									Website for any purpose that is unlawful or prohibited by these T&Cs. You may not use the
									Website in any manner that could damage, disable, overburden, or impair the Website or
									interfere with any other party’s use and enjoyment of the Website. You may not obtain or attempt
									to obtain any materials or information through any means not intentionally made available or
									provided for through the Website.
								</li>
								<li>This Licence is subject to these T&Cs. Any other use of the Company’s services not expressly
									permitted by these T&Cs is prohibited. All other rights are reserved by the Company and its
									licensors, if any, including that to any content or functionality as presented on the Website.
								</li>
								<li>All content included on the Website, such as text, graphics, logos, images, as well as the
									compilation thereof, and any software used on the Website (collectively, "Company Content"),
									is the property of the Company or its suppliers and protected by copyright, trademark and other
									laws that protect intellectual property and proprietary rights. You agree to observe and abide by
									all copyright and other proprietary notices, legends, or other restrictions contained in any such
									content and will not make any changes thereto.</li>
								<li>You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create
									derivative works, or in any way exploit any of the Company Content, in whole or in part, found on
									the Website. Company Content is not for resale. Your use of the Website does not entitle you to
									make any unauthorized use of any Company Content, and in particular, you will not delete or
									alter any proprietary rights or attribution notices in any Company Content. You will use Company
									Content solely for your personal use and will make no other use of Company Content without
									the express written permission of the Company and other copyright owners if any. You agree
									that you do not acquire any ownership rights in any Company Contents. We do not grant you
									any licenses, express or implied, to the intellectual property of the Company or our licensors, if
									any, except as expressly authorised by these T&Cs.</li>
							</ol>
						</li>

						<li>
						<strong>TERMINATION</strong>
							<ol>
								<li>We may terminate or suspend your Licence to use the Website and/or any services available
									thereunder without prior notice or liability for any reason whatsoever, including (but not limited to)
									breaching of any element, however minor, of these T&Cs. Nothing in these T&Cs or in any other
									communication or action by the Company or our employees, agents, or representatives should be
									taken as a waiver of any legal remedies available for any event causing termination.</li>
								<li>THE USE OF THE WEBSITE IS UNAUTHORIZED IN ANY JURISDICTION THAT DOES NOT
									GIVE EFFECT TO ALL PROVISIONS OF THESE T&Cs AND SUCH UNAUTHORISED USE
									COULD RESULT IN THE TERMINATION OR SUSPENSION OF YOUR LICENCE TO USE THE
									WEBSITE.</li>
								<li>All provisions of the T&Cs which by their nature should survive termination shall survive
									termination, including (but not limited to) ownership provisions, disclaimers or limitations of
									obligations or liability, and indemnity.
								</li>
							</ol>
						</li>

						<li>
						<strong>ASSIGNMENT</strong>
							<ol>
								<li>The Company reserves the right to assign any and all of its rights or obligations hereunder to a
									third party without your written consent. You shall not assign any or all of your rights or
									obligations hereunder to any third person or entity whatsoever.</li>
							</ol>
						</li>

						<li>
						<strong>LINKS TO OTHER WEBSITES</strong>
							<ol>
								<li>The Company or other users may provide links to third-party websites or services that are not
									owned or controlled by the Company. The Company has no control over and assumes no
									responsibility for the content, privacy policies, or practices of any third-party websites or
									services.</li>
								<li>You further acknowledge and agree that the Company shall not be responsible or liable, directly
									or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use
									of or detrimental reliance on any information, content, goods, or services available on or through
									any such web sites or services. The Company is not liable for any loss or damage incurred as a
									result of interacting with any third party content on our Website.</li>
								<li>The Company cannot guarantee or verify the contents of any externally linked website despite
									their best efforts. You should therefore note that you click on external links at your own risk and
									our Website and the Company cannot be held liable for any damages or implications caused by
									visiting any external links mentioned.</li>
								<li>The Website may contain sponsored links and adverts. These will typically be served through
									our advertising partners to whom may have detailed privacy policies relating directly to the
									adverts they serve.</li>
								<li> Clicking on any such adverts will send you to the advertisers’ website through a referral program
									that may use cookies and will track the number of referrals sent from the Company’s Website.
									This may include the use of cookies which may, in turn, be saved on your computer's hard drive.
									Users should therefore note they click on sponsored external links at their own risk and our
									Website and its owners cannot be held liable for any damages or implications caused by visiting
									any external links mentioned.</li>
							</ol>
						</li>

						<li>
						<strong>NO REPRESENTATIONS
						</strong>
							<ol>
								<li>The Company does not make or purports to make, and hereby disclaims, any representation,
									warranty, or undertaking in any form whatsoever to any entity or person, including any
									representation, warranty, or undertaking in relation to the truth, accuracy, and completeness of
									any of the information set out in the Website, its contents and/or these T&Cs.
								</li>
							</ol>
						</li>

						<li>
						<strong>DISCLAIMER OF WARRANTIES</strong>
							<ol>
								<li>THE USER EXPRESSLY AGREES THAT IT IS ACCESSING AND USING THE WEBSITE AT
									THE PURCHASER'S SOLE RISK AND THAT THE WEBSITE IS PROVIDED ON AN "AS IS"
									BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
									INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF TITLE OR IMPLIED WARRANTIES,
									MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE (EXCEPT ONLY TO THE
									EXTENT PROHIBITED UNDER APPLICABLE LAW WITH ANY LEGALLY REQUIRED
									WARRANTY PERIOD TO THE SHORTER OF THIRTY DAYS FROM FIRST USE OR THE
									MINIMUM PERIOD REQUIRED).
								</li>
								<li>Without limiting the foregoing, none of the Company Team warrants that the process for
									accessing or using the Website will be uninterrupted or error-free.</li>
							</ol>
						</li>

						<li>
						<strong>INDEMNIFICATION</strong>
							<ol>
								<li>To the fullest extent permitted by applicable law, you hereby and irrevocably and unconditionally
									undertake to indemnify, and keep indemnified, defend and hold harmless the Company Team
									from and against all claims, demands, actions, damages, losses, costs, and expenses (including
									without any limitation all legal costs and expenses) that arise from or relate to: (i) your use of the
									Website or its contents; (ii) your responsibilities or obligations under these T&Cs\; (iii) your
									violation of these T&Cs\; or (iv) your violation of any rights of any other person or entity.
								</li>
								<li>If for any reason you hereafter bring or commence any action or legal proceeding in respect of
									any claim purported to be released and discharged pursuant to these T&Cs, or otherwise
									attempt to pursue any such claim against the Company or any of the Company’s Team, then you
									hereby and irrevocably and unconditionally undertake to indemnify, and keep indemnified,
									defend and hold harmless the Company and all Company’s Team fully on demand from and
									against:

									<ol>
										<li>all liabilities or losses suffered by the Company and/or any Company’s Team; and</li>
										<li> all reasonable costs, charges, and reasonable expenses (including without limitation
											legal costs and expenses) reasonably and properly incurred by the Company and/or
											any Company Team, in each case by reason of or in connection with the bringing or
											commencement of such action or pursuit of such claim by you. If any provision or
											part-provision of this section is or becomes invalid, illegal, or unenforceable, it shall be
											deemed modified to the minimum extent necessary to make it valid, legal, and
											enforceable. If such modification is not possible, the relevant provision or
											part-provision shall be deemed deleted. Any modification to or deletion of a provision
											or part-provision under this section shall not affect the validity and enforceability of the
											rest of this section.
										</li>
									</ol>
								</li>
								<li>The Company reserves the right to exercise sole control over the defense, at your expense, of
									any claim subject to indemnification under this Section 9. This indemnity is in addition to, and not
									in lieu of, any other indemnities set forth in this written agreement between you and the
									Company.
								</li>
							</ol>
						</li>

						<li>
						<strong>COMPLETE AGREEMENT</strong>
							<ol>
								<li> These T&Cs set forth the entire understanding between the User and the Company with respect
									to the usage of the Website and they supersede and novate any public statements about the
									Website or its contents made by third parties or by the Company Team or individuals associated
									with any of the Company Team, past and present.</li>
							</ol>
						</li>

						<li>
						<strong>LIMITATIONS TO SEVERABILITY</strong>
							<ol>
								<li> The Purchaser and the Company agree that if any portion of these T&Cs is found illegal or
									unenforceable in one or more jurisdictions, in whole or in part, such provision shall, as to such
									jurisdiction, be ineffective solely to the extent of such determination of invalidity or
									unenforceability without affecting the validity or enforceability thereof, in any other manner or
									jurisdiction, and without affecting the remaining provisions of the T&Cs, which shall continue to
									be in full force and effect.
								</li>
							</ol>
						</li>

						<li>
						<strong>NO WAIVER</strong>
							<ol>
								<li>The failure of the Company to require or enforce strict performance by the Purchaser of any
									provision of these T&Cs or the Company’s failure to exercise any right under these T&Cs shall
									not be construed as a waiver or relinquishment of the Company's right to assert or rely upon any
									such provision or right in that or any other instance.
								</li>
								<li>The express waiver by the Company of any provision, condition, or requirement of these T&Cs
									shall not constitute a waiver of any future obligation to comply with such provision, condition, or
									requirement. Except as expressly and specifically, set forth in these T&Cs, no representations,
									statements, consents, waivers, or other acts or omissions by the Company Team shall be
									deemed a modification of these T&Cs nor be legally binding.</li>
							</ol>
						</li>

						<li>
						<strong>UPDATES TO THE T&CS</strong>
							<ol>
								<li>The Company reserves the right, at its sole discretion, to change, modify, add, or remove
									portions of the T&Cs at any time during the sale and up to six months after the sale is closed by
									posting the amended T&Cs on the Website. Any User will be deemed to have accepted such
									changes by accessing and/or using the Website.</li>
							</ol>
						</li>

						<li>
						<strong>LANGUAGE</strong>
							<ol>
								<li>Only English versions of these T&Cs and any or all of the Company’s communications
									are considered official. The English version shall prevail in case of differences in
									translation.
								</li>
							</ol>
						</li>

						<li>
						<strong>JURISDICTION</strong>
							<ol>
								<li>The T&Cs and all non-contractual obligations arising in any way whatsoever out of or in
									connection with these T&Cs are governed by, construed, and take effect in accordance with
									St. Vincent and the Grenadines law.</li>
								<li>The Parties irrevocably agree that the Courts of St. Vincent and the Grenadines shall have
									exclusive jurisdiction to hear and decide any suit, action, or proceedings, and/or to settle
									any disputes, which may arise out of or in connection with these T&Cs or their formation or
									validity and, for these purposes, each party irrevocably submits to the jurisdiction of
									the courts of St. Vincent and the Grenadines.</li>
							</ol>
						</li>
					</ol>
				</article>
			</div>
		</div>

		<Footer></Footer>
	</div>
</template>

<script>
export default {
	name: "TermsConditions"
}
</script>

<style scoped>

article {
	font-size: 1em;
}

table {
	font-size: 0.8em;
}

br {

}
</style>
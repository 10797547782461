<template>
	<div class="container">
		<div class="card mt-5">
			<div class="card-body">
				<article class="editor px-tablet-40" data-aos="fadeIn .7s ease-in-out-circ .2s, loop">
					<p style="text-align: center;"><strong>ALPHABATEM</strong><br/><strong><span
							style="text-decoration: underline;">PRIVACY POLICY</span></strong><br/><em><span
							style="text-decoration: underline;">International and EU GDPR</span></em></p>
					<p style="text-align: center;"></p>
					<p >Alphabatem LLC, a company registered in St. Vincent and the Grenadines (the
						“Company”), declares the following Privacy Policy, confirming that every defined word or term has the same
						meaning and purpose as stated in its relevant Website Terms and Conditions (“T&amp;Cs”), in relation to:</p>
					<p >I. any information the Company collects about the User when he/she uses any of
						the Company’s software services, and/or otherwise interacts with any Company product and/or member of the
						Company’s Team;<br/>II. how the Company uses, shares, stores, and secures the information; and<br/>III. how
						the User may access and control that information.</p>
					<ol>
						<li ><strong>User Information.</strong><br/>1.1. The Company may collect any
							''<strong>Personal Information</strong>'', which is any data, information, or combination of<br/>data and
							information that is provided by the User to the Company, or through the use of the Company software<br/>and
							that relates to an identifiable individual, including throughout any interactions with the Company's
							website.<br/>1.2. The Company collects the Personal Information when it is provided by the User or when
							the User interacts<br/>with the Company's software.<br/>1.3. The Company does not collect sensitive data
							or special category data about the User. This includes details<br/>about race, ethnic origin, politics,
							religion, trade union membership, genetics, biometrics, health, or sexual<br/>orientation.
						</li>
						<li><strong>Information Usage.</strong><br/>2.1. The Company will only use any Personal Information that the
							applicable law allows to and in particular:<br/>2.1.1. to provide customer support and personalized
							features, and to protect the safety and security of<br/>the software;<br/>2.1.2. to satisfy a legitimate
							interest which is not overridden by the User’s fundamental rights or data<br/>protection interests, for
							example for research and development, and in order to protect the Company’s<br/>legal rights and
							interests;<br/>2.1.3. when the User consents to do so for a specific purpose;<br/>2.1.4. when the Company
							needs to comply with a legal or regulatory obligation.<br/>2.2. When the User has given consent to use any
							Personal Information for a specific purpose, he/she has the<br/>right to withdraw said consent at any time
							by contacting the Company, but this will not affect any use of the<br/>Personal Information that has
							already taken place.<br/>2.3. The Company does not share any Personal Information with any company outside
							its own group, if<br/>applicable, for marketing purposes, unless there is an express specific consent to
							do so.<br/>2.4. For users of the software who are located in the European Union, the legal bases for
							processing<br/>their information are published in the Legal Bases Table at the end of this Policy under
							Clause 9.
						</li>
						<li><strong>Information Sharing.</strong><br/>3.1. The Company may share Personal Information with third
							parties that help it operate, provide, support,<br/>improve, and market its software, products, and
							services, for example, third-party service providers who provide<br/>website and application development,
							data storage and backup, infrastructure, payment processing, customer<br/>support, business analytics,
							Anti-Money Laundering (''<strong>AML</strong>'') and Know Your Customer checks (''<strong>KYC</strong>'')
							and<br/>other relevant services.<br/>3.2. Third-party service providers have access to Personal
							Information only for the purpose of performing their<br/>services and in compliance with applicable laws
							and regulations. These third-party service providers are required<br/>to maintain confidentiality and
							security of all Personal Information that they process on the Company’s behalf<br/>and to implement and
							maintain reasonable security measures to protect the confidentiality, integrity, and<br/>availability of
							any Personal Information.<br/>3.3. The Company takes reasonable steps to confirm that all third-party
							service providers process Personal<br/>Information in a manner that provides at least the same level of
							protection as is provided under this Privacy<br/>Policy. Where any third-party service provider is unable
							to satisfy these requirements, any reasonable steps to<br/>prevent or stop non-compliant processing will
							be taken.<br/>3.4. The Company may share Personal Information on an aggregated or de-identified basis with
							third parties for<br/>research and analysis, profiling, and similar purposes to help the improvement of
							products and services.<br/>3.5. If the Users use any third-party software in connection with our products
							or services, third-party software<br/>providers can gain access to the Personal Information. Policies and
							procedures of third-party software providers<br/>are not controlled by the Company, and this Privacy
							Policy shall not cover how any Personal Information is<br/>collected or used by third-party software
							providers. The User is encouraged to review the privacy policies of<br/>third-party software providers
							before using third-party software.<br/>3.6. The software may contain links to third-party websites over
							which the Company has no control. If the Users<br/>follow a link to any of these websites or submit
							information to them, any Personal Information will be governed<br/>by their policies. We encourage the
							User to review the privacy policies of third-party websites before submitting<br/>any information to them.<br/>3.7.
							The Company may share any Personal Information with government and law enforcement officials to<br/>comply
							with applicable laws or regulations.
						</li>
						<li><strong>Information Storage</strong><br/>4.1. All Personal Information provided by the User is stored on
							the Company’s servers or on a third-parties<br/>server to which only the Company has access to within the
							boundaries of relevant laws and regulations.<br/>4.2. The Company only retains Personal Information for so
							long as it is reasonably necessary to fulfill the<br/>purposes they were collected for, including for the
							purposes of satisfying any legal, accounting, or reporting<br/>requirements.
						</li>
						<li><strong>International Transfer of Information</strong><br/>5.1. The Company collects any Personal
							Information globally and transfers, processes, and stores any Personal<br/>Information outside the User’s
							country of residence where the Company’s or any third-party service providers<br/>operate for the purpose
							of providing the products and services.<br/>5.2. Some of the Countries in which the Company or third-party
							service providers are operating or are<br/>located may not have the privacy and data protection laws that
							are equivalent to those in the User’s<br/>country of residence. When Personal Information is shared with
							these companies or third-party service<br/>providers, use is made of contractual clauses, corporate rules,
							and other appropriate mechanisms to safeguard<br/>the transfer of any Personal Information.
						</li>
						<li><strong>User’s rights</strong><br/>6.1. The User has the following right to:<br/>6.1.1. be informed of
							what the Company does with the relevant Personal Information;<br/>6.1.2. request a copy of relevant
							Personal Information the Company holds;<br/>6.1.3. require the Company to correct any inaccuracy or error
							in any Personal Information held;<br/>6.1.4. request erasure of any relevant Personal Information, except
							the one held for record-keeping<br/>purposes, to complete transactions, or to comply with legal
							obligations);<br/>6.1.5. object to or restrict the processing of any relevant Personal Information
							(including for marketing<br/>purposes);<br/>6.1.6. request to receive some of the relevant Personal
							Information in a structured, commonly used, and<br/>machine-readable format, and request that it is
							transferred to another party; and<br/>6.1.7. withdraw consent at any time where the Company is relying on
							consent to process the relevant<br/>Personal Information (although this will not affect the lawfulness of
							any processing carried out before<br/>such consent withdrawal).<br/>6.2. The software enable Users to
							update certain information about themselves.<br/>6.3. The User may opt-out of receiving marketing
							materials from the Company by expressly contacting it.<br/>However, the User will continue to receive
							notifications or information that are necessary for the use of the<br/>Company’s products or
							services.<br/>6.4. As a security measure, the Company may need specific information from the User to help
							confirm his/her<br/>identity when processing any privacy requests or when you exercise the rights stated
							in this Clause.<br/>6.5. Any request under paragraph 6.1 will normally be addressed free of charge.
							However, the Company may<br/>charge a reasonable administration fee if the request is clearly unfounded,
							repetitive, or excessive.<br/>6.6. The Company will respond to all legitimate requests approximately
							within one (1) month. Occasionally, it<br/>may take longer than one (1) month if the request is
							particularly complex or if the users have made a number of<br/>requests.
						</li>
						<li><strong>Policy Changes</strong><br/>7.1. The Company reserves the right to amend this Privacy Policy
							from time to time by posting the updated<br/>Privacy Policy on the software or connected website or app
							store. By continuing to use the Company’s<br/>software after the changes come into effect, the User agrees
							to be bound by the revised policy.
						</li>
						<li><strong>Children Privacy Policy</strong><br/>8.1. The Company’s products and services are not directed
							or intended for individuals not major of age in their<br/>country of residence. The Company does not
							knowingly collect any personal information from individuals not<br/>major of age and if this will be
							detected, steps will be taken to delete such information.
						</li>
						<li><strong>European Union Users - GDPR</strong><br/>9.1. If the User is interacting with the Company’s
							software from the European Union (''EU''), that may differ<br/>from privacy laws under other
							jurisdictions. The User acknowledges that he may be transferring relevant<br/>Personal Information to the
							Company for storage and processing in other countries around the world for the<br/>purposes described
							under this Privacy Policy. The Company takes the utmost care in protecting any relevant<br/>Personal
							Information and has put in place adequate mechanisms to protect it when it is transferred<br/>internationally.<br/>9.2.
							The Legal Bases for collecting and using any relevant Personal Information if the User is a citizen
							or<br/>resident of the EU, will depend on the Personal Information concerned and the specific context in
							which it is<br/>collected:<br/>● <strong>Performance of a contract.</strong> The use of any relevant
							Personal Information may be necessary to perform<br/>the terms and conditions or other policies under
							which we operate our software.<br/>● <strong>Consent.</strong> The User consent is needed if technical
							information such as cookie data and geolocation data<br/>is necessary, and any relevant Personal
							Information is used for marketing purposes. The User may<br/>withdraw his/her consent at any time by
							contacting the Company directly.<br/>● <strong>Legitimate interests.</strong> The Company may use any
							Personal Information for its legitimate interests to<br/>improve its software, for security purposes, and
							fraud prevention, and to share information with any<br/>affiliates for internal administration. In such
							circumstances, the Company ensures that these interests are<br/>not overridden by the User’s data
							protection interests or fundamental rights and freedoms.<br/>9.3. Legal Bases Table
							<p></p>
							<table class="table table-sm text-white" width="100%" cellspacing="0" cellpadding="4">
								<tbody>
								<tr>
									<td>
										<p><strong>Processing purpose</strong></p>
									</td>
									<td>
										<p><strong>Type of data processed</strong></p>
									</td>
									<td>
										<p><strong>Legal bases</strong></p>
									</td>
								</tr>
								<tr>
									<td>
										<p>To enable the User to access products and services</p>
									</td>
									<td>
										<p>Account Data, Transaction Data, Support Data, Technical Data [and User Content]</p>
									</td>
									<td>
										<p>Contract performance</p>
									</td>
								</tr>
								<tr>
									<td>
										<p>To administer and maintain he safety and security of the software</p>
									</td>
									<td>
										<p>Technical Data</p>
									</td>
									<td>
										<p>Contract performance</p>
									</td>
								</tr>
								<tr>
									<td>
										<p>Technical Data To study the usage of products or services</p>
									</td>
									<td>
										<p>Transaction Data, Support Data, Technical Data, Usage Data</p>
									</td>
									<td>
										<p>Legitimate interest to improve the software</p>
									</td>
								</tr>
								<tr>
									<td>
										<p>To gather feedback on products, services, or features</p>
									</td>
									<td>
										<p>Account Data</p>
									</td>
									<td>
										<p>Legitimate interest to improve the software</p>
									</td>
								</tr>
								</tbody>
							</table>
							<p></p>
							<br/>9.4. Rights under EU law:<br/>If the User’s relevant Personal Information is subject to the
							protections offered by EU law, he/she may:<br/>● Access, correct, update or request deletion of any
							relevant Personal Information, at any time by<br/>contacting the Company using the contact details
							provided on the software or website (in accordance with<br/>applicable data protection laws) or writing to
							the support group therein indicated, provided that the<br/>Company may charge a reasonable fee for any
							manifestly unfounded, excessive or repetitive requests;<br/>● Object to the processing of any relevant
							Personal Information, ask the Company to restrict processing of<br/>any relevant Personal Information or
							request portability of any relevant Personal Information for the<br/>legitimate interests set out above.
							In certain circumstances, the Company may not be able to stop using<br/>the Personal Information, with
							motivated cause.<br/>● Withdraw consent at any time if the Company collected and processed any relevant
							Personal Information<br/>with consent;<br/>● Opt-out of any marketing communications that the Company (or
							any third party to whom the Company<br/>disclosed the Personal Information with consent) may send;<br/>●
							Complain to a data protection authority about the Company’s collection and use of any relevant
							Personal<br/>Information.<br/>These rights apply only to Users who are subject to EU law.<br/>9.5. The
							Company is the Data Controller in relation to its Website and is responsible for any relevant
							Personal<br/>Information, save for what pertains to other third-party service providers connected. The
							User may contact<br/>the Company through its software, website or by writing on the support groups therein
							provided if he/she<br/>has any concerns about this Policy and any relevant Personal Information.
						</li>
					</ol>
					<p></p>
				</article>
			</div>
		</div>

		<Footer></Footer>
	</div>
</template>

<script>
import Footer from "../../components/Footer";
export default {
	name: "PrivacyPolicy",
	components: {Footer}
}
</script>

<style scoped>
article {
	font-size: 1em;
}

table {
	font-size: 0.8em;
}

br {

}
</style>